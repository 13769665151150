<template>
  <div>
    <div class="history-select">
      <div class="history-select-title">{{ $t('history_title') }}</div>
      <b-form-select
        v-if="isLang === 0"
        class="rounded-0 icon-chevron-down w-50 justify-content-center"
        v-model="selected"
        :options="privacyDetailData"
        value-field="item"
        text-field="name"
        @change="launchEditForm(selected)"
      >
      </b-form-select>
      <b-form-select
        v-if="isLang === 1"
        class="rounded-0 icon-chevron-down w-50 justify-content-center"
        v-model="selected"
        :options="privacyDetailDataEN"
        value-field="item"
        text-field="name"
        @change="launchEditForm(selected)"
      >
      </b-form-select>
    </div>
    <hr />
    <!-- 개인정보 처리방침 Detail Frame -->
    <component :is="currentEditForm" />
  </div>
</template>

<script>
import GeneralUtil from '@/util/GeneralUtil';

export default {
  name: 'Privacyinfo',
  mixins: [GeneralUtil],

  data() {
    return {
      privacyDetailData: [
        {item: '20240201', name: '2024-02-01'},
        {item: '20231010', name: '2023-10-06'},
        {item: '20230817', name: '2023-08-17'},
        {item: '20181123', name: '2018-11-23'},
        {item: '20160920', name: '2016-09-20'},
        {item: '20150526', name: '2015-05-26'},
        {item: '20130901', name: '2013-09-01'},
      ],
      privacyDetailDataEN: [
        {item: '20240201', name: '2024-02-01'},
        {item: '20231010', name: '2023-10-06'},
      ],
      componentToDisplay: null,
      selected: '20240201',
      isLang: 0,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  computed: {
    currentEditForm: function () {
      if (this.componentToDisplay) {
        return () =>
          import(`@/pages/Footerlink/Components/PrivacyinfoDetails/PrivacyinfoDetail_${this.componentToDisplay}`);
      }
      return () =>
        import(`@/pages/Footerlink/Components/PrivacyinfoDetails/PrivacyinfoDetail_${this.privacyDetailData[0].item}`);
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: this.$t('policy_title')});
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
    launchEditForm(msg) {
      window.scrollTo(0, 0);
      this.componentToDisplay = msg;
      this.selected = msg;
    },
  },
};
</script>

<style scoped lang="scss">
.history-select {
  display: flex;
  margin: auto auto auto 0rem;
}
.history-select-title {
  line-height: 2.3rem;
  text-align: left;
  margin: 0rem 1rem;
  font-size: 0.9rem;
  letter-spacing: -0.03rem;
}
@media (min-width: 1200px) {
  .history-select {
    width: 50%;
  }
  .history-select-title {
    width: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .history-select {
    width: 70%;
  }
  .history-select-title {
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .history-select {
    width: 100%;
  }
  .history-select-title {
    width: auto;
  }
}
@media (max-width: 767px) {
  .history-select {
    width: 100%;
  }
  .history-select-title {
    width: auto;
  }
}
</style>

<i18n>
{
  "ko": {
    "history_title": "개인정보처리방침 변경 이력",
    "policy_title": "개인정보 처리 방침"
  },
  "en": {
    "history_title": "History of SK Pinx Policy",
    "policy_title": "Privacy Policy"
  },
  "ja": {
  }
}
</i18n>
